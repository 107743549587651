.sb-view-invite---container {
	position: relative;
	display: block;
	> h1 {
		font-size: 16pt;
		text-align: center;
		line-height: 24pt;
		margin-bottom: var(--padding---global);
	}
	> h2 {
		font-size: 11pt;
		text-align: center;
	}
	> h6 {
		font-size: 11pt;
		text-align: center;
		margin-bottom: var(--padding---global);
	}
	> h5 {
		font-size: 14pt;
		text-align: center;
		line-height: 24pt;
	}
}
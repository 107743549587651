.com-button-view-event---component {
	display: flex;
	justify-content: center;
	gap: 10pt;
	font-weight: bold;
	color: var(--color---folly);
	height: 25pt;
	> i {
		display: block;
		height: 25pt;
		line-height: 25pt;
	}
	> span {
		display: block;
		height: 25pt;
		line-height: 25pt;
	}
}
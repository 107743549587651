//#region 																							I M P O R T S

	@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css');
	@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

//#endregion

//#region 																							O V E R R I D E S

	:root {

		--aspect-ratio--posters: 9 / 16;
		--aspect-ratio--posters-horizontal: 16 / 7;

		--border-radius---button: 7pt;
		--border-radius---card: 10pt;

		--color---light: #FFFFFF;
		--color---shadow: #F4F4F4;
		--color---folly: #263238;
		--color---greeny: #09BC89;
		--color---yelly: #FFCB22;
		--color---reddy: #eb445a;
		--color---blacky: #7e7e7e;
		--color---dark: #0D0D0D;

		--padding---global: 10pt;

		--shadow---standard: 0pt 0pt 6pt 0.5pt rgba(0, 0, 0, 0.15);
		--shadow---inset: inset 0pt 0pt 6pt 0.5pt rgba(0, 0, 0, 0.15);

	}

//#endregion

//#region 																							O V E R R I D E S

	* {
		box-sizing: border-box !important;
	}

	*:not(i) {
		font-family: 'Quicksand', sans-serif !important;
		font-style: normal;
		font-optical-sizing: auto;
	}

	::-webkit-scrollbar,
	*::-webkit-scrollbar {
		-webkit-appearance: none !important;
		display: none !important;
		opacity: 0 !important;
		width: 0pt !important;
	}

	.scroll-bar-indicator {
		display: none !important;;
		opacity: 0 !important;
	}

//#endregion

//#region 																							C O N T A I N E R S

	.sb-page---container---standard {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100vw;
		height: calc(100vh - 40pt);
		display: block;
		justify-content: center;
		flex-direction: column;
		padding: var(--padding---global);
		> section {
			display: flex;
			justify-content: center;
			flex-direction: row;
			width: calc(100vw - (var(--padding---global) * 2));
			height: auto;
			> div {
				display: block;
				max-width: 300pt !important;
				height: 100%;
			}
		}
	}

//#endregion

//							* 										{ outline: 1pt solid orange !important; }
.com-button-back---container {
	position: fixed;
	z-index: 221331;
	top: 0;
	right: 0;
	left: 0;
	width: 100vw;
	height: 50pt !important;
	padding: 10pt;
	display: block;
	background-color: var(--color---light);
	> div {
		display: flex;
		justify-content: flex-start;
		gap: 10pt;
		> i {
			display: block;
			width: 30pt;
			height: 30pt;
			line-height: 30pt;
			text-align: center;
			background-color: var(--color---folly);
			color: var(--color---light);
			border-radius: var(--border-radius---button);
		}
		> span {
			display: block;
			height: 30pt;
			line-height: 30pt;
			font-size: 12pt;
		}
	}
}
.sb-view-event---container {
	display: block;
	padding: 50pt var(--padding---global);
	> div {
		display: block;
		width: 100%;
		margin: 0;
		min-height: 120pt;
		height: auto;
		overflow: hidden;
		border-radius: var(--border-radius---global);
		> img {
			display: block;
			width: 100%;
			object-fit: contain;
			background-color: transparent;
			border-radius: var(--border-radius---global);
			background-color: var(--color---shadow);
			margin: 0;
		}
	}
	> h1 {
		font-size: 14pt;
		line-height: 24pt;
		margin-bottom: var(--padding---global);
	}
}
.com-map-preview---component {
	position: relative;
	display: block;
	width: 100%;
	height: 75pt !important;
	padding: 0 !important;
	margin: 0 !important;
	border-radius: var(--border-radius---card) !important;
	overflow: hidden !important;
	> div {
		display: block;
		width: 100%;
		height: 120pt;
		pointer-events: none !important;
		touch-action: none !important;
		padding: 0 !important;
		margin: 0 !important;
		pointer-events: none !important;
		touch-action: none !important;
		border-radius: var(--border-radius---card) !important;
		> div {
			display: block;
			width: 100%;
			height: 120pt;
			padding: 0 !important;
			margin: 0 !important;
		}
	}
}
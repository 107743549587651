.com-event-text---component {
	position: relative;
	display: block;
	overflow: hidden;
	> input {
		display: none;
		&:checked {
			+ div {
				height: auto !important;
				> div {
					display: none;
				}
			}
		}
	}
	> div {
		position: relative;
		display: block;
		height: 100pt;
		transition: 0.15s;
		> p {
			display: block;
			margin: 0;
			height: auto;
			line-height: 1.25;
			font-size: 11pt;
			white-space: pre;
		}
		> div {
			position: absolute;
			z-index: 1;
			right: 0;
			bottom: 0;
			left: 0;
			display: flex;
			justify-content: center;
			background: linear-gradient(0deg, var(--color---light) 50%, transparent 100%);
			padding: calc(var(--padding---global) * 2) var(--padding---global) var(--padding---global) var(--padding---global);
		}
	}
}
.com-copyright---container {
	position: fixed;
	z-index: 12131;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100vw;
	height: 50pt;
	padding: 10pt;
	display: block;
	background-color: var(--color---light);
	> img {
		display: block;
		width: 100%;
		height: 12pt;
		line-height: 12pt;
		margin: 0;
		padding: 0;
	}
	> p {
		display: block;
		font-size: 9pt;
		line-height: 10pt;
		margin: 5pt 0 0 0;
		padding: 0;
		text-align: center;
		> a {
			font-weight: bold;
		}
	}
}